import React, { memo } from 'react'
import cn from 'classnames'
import Link from 'next/link'
// Components
import styles from './Button.module.scss'
import { ButtonProps } from '@/types/index'

export default memo(function Button({
  id,
  disabled,
  title,
  subtitle,
  onClick = () => {},
  leftImage,
  rightImage,
  centerImage,
  theme = ['tall', 'pink-solid'],
  style,
  width = 25,
  height = 25,
  altImg,
  hRef = '',
  className: aTagClasses = styles.aTag,
}: ButtonProps) {
  const classes = {
    [styles.button]: true,
    [styles.centerImage]: !!centerImage,
    [styles.disabled]: disabled,
  }

  if (typeof theme === 'string') theme = [theme]
  theme.forEach((t) => {
    classes[styles[t]] = typeof t !== 'undefined'
  })

  const buttonElement = (
    <button
      onClick={(e) => {
        if (!disabled && !hRef) {
          e.preventDefault()
          onClick()
        }
      }}
      className={cn(classes)}
      style={style}
    >
      {leftImage ? (
        <img
          src={leftImage}
          className={styles.leftImage}
          alt={altImg}
          width={width}
          height={height}
        />
      ) : null}

      {(title || subtitle) && !centerImage ? (
        <div className={styles.content}>
          {title ? <div className={styles.title}>{title}</div> : null}
          {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
      ) : null}

      {!title && !subtitle && centerImage ? (
        <img src={centerImage} alt={altImg} width={width} height={height} />
      ) : null}

      {rightImage ? (
        <img
          src={rightImage}
          className={styles.rightImage}
          alt={altImg}
          width={width}
          height={height}
        />
      ) : null}
    </button>
  )

  return hRef ? (
    <Link href={hRef} className={cn(aTagClasses)} id={id?.toString()}>
      {buttonElement}
    </Link>
  ) : (
    buttonElement
  )
})
